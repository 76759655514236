(function ($, ns) {
  "use strict";

  var moduleDownload = function ($context, opts) {
    this.dom = $context;
    this.$context = $($context);

    var defaultOpts = {
      pushState: (ns.download && ns.download.opts && ns.download.opts.pushState !== undefined) ? ns.download.opts.pushState : true,
      autoSubmitFilters: (ns.download && ns.download.opts && ns.download.opts.autoSubmitFilters !== undefined) ? ns.download.opts.autoSubmitFilters : true,
    };
    opts = opts || {};
    this.opts = $.extend(defaultOpts, opts);

    this.init();
  };

  moduleDownload.prototype = {
    init: function () {
      var self = this;
      self.registerEvents();

      if (this.opts.pushState) {
        window.history && history.replaceState({
          'module': 'download',
          'href': window.location.href
        }, null, window.location.href);
        window.onpopstate = function (event) {
          if (event.state && event.state.module && event.state.module === 'download' && event.state.href) {
            self.loadPage(event.state.href);
          }
        }
      }

      self.recomputeIndexes();
    },
    registerEvents: function () {
      this.registerPagination();
    },
    recomputeIndexes: function () {
      jQuery('.module-telechargements').each(function (i, instance) {
        $(instance).data('index', i);
      });
    },
    loadPage: function (href, cb) {
      var self = this;
      self.$context.addClass('ajax-loading');
      $.get(href, {}, function (res) {
        self.$context.removeClass('ajax-loading');
        var index = self.$context.data('index');
        var $newContext = null;
        if (index) {
          $newContext = $($(res).find('.module-telechargements')[index]);
          $newContext.data('index', index);
        } else {
          $newContext = $(res).find('.module-telechargements').first();
        }
        self.$context.replaceWith($newContext);
        self.$context = $newContext;

        var topPos = self.$context.offset().top;
        if (window.smoothScrollMargin) {
          topPos -= window.smoothScrollMargin;
        }

        $('html,body').animate({
          scrollTop: topPos
        }, 750);

        self.registerEvents();

        if (self.opts.pushState) {
          window.history && history.pushState({'module': 'download', 'href': href}, null, href);
        }

        if (ns.theme && ns.theme.runCurrentPageJs) {
          ns.theme.runCurrentPageJs(self.$context);
        }
        if (window.pew) {
          window.pew.enhanceRegistry(self.$context[0]);
        }
        cb && cb();
      });
    },
    registerPagination: function () {
      var self = this;
      this.$context.find('.pagination a.navlink').on('click', function (e) {
        e.preventDefault();
        $(this).replaceWith('<span class="loader loader--small"></span>')
        self.loadPage($(this).attr('href'));
      })
    }
  };

  if (ns && ns.app) {
    ns.app.registerModule('download', moduleDownload);
  } else {
    window.pew.addRegistryEntry({
      key: 'wdf-plugin-download',
      domSelector: '.module-telechargements',
      classDef: moduleDownload
    });
  }

})(jQuery, window.wonderwp);
