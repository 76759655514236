import ParcoursComponent from "../../../../../../plugins/wwp-parcours/public/js/ParcoursComponent";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

export default class ParcoursThemeComponent extends ParcoursComponent {

  registerScroll() {
    //Deactivate on page consos
    if ($('body').hasClass('page-consos')) {
      this.element.addClass('no-scroll');
      return false;
    }

    /* Panels */
    const
      $panelsContainer = this.element.find(".scenes-wrap > ul"),
      panels = gsap.utils.toArray(this.element.find(".scene")),
      tween = gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: $panelsContainer,
          pin: true,
          start: "top top",
          scrub: 1,
          end: () => "+=" + ($panelsContainer[0].offsetWidth - innerWidth)
        }
      });
  }

}

window.pew.addRegistryEntry({
  key: 'parcours-component',
  domSelector: '.module-wwp-parcours .parcours-detail',
  classDef: ParcoursThemeComponent
});
